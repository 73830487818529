import React, { useState, useEffect } from 'react';
import Table from '../../Components/Table/Table'; 
import axios from "../../authAxios";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

export default function FancyDeclaration() {
  const [fancies, setFancies] = useState([]);
  const [runs, setRuns] = useState(0);

  const fetchData = async () => {
    try {
      const result = await axios.get("/declare/getUndeclareFancy"); 
      setFancies(result.data.dataobj);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
   
    fetchData();
    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const declareFancy = async (fancyId, matchId, runs) => {
    try {
      const response = await axios.post("/declare/fancy", { selection_id:fancyId, match_id:matchId, settled_at:runs });
      if(response){
        fetchData();
      }
    } catch (error) {
      console.error(error);
    }
  }
  const columns = [
    { name: "Match Id", selector: (row) => row.match_id ,width:"100px"},
    { name: "Fancy Id", selector: (row) => row.selection_id,width:"90px" },
    { name: "Match Name", selector: (row) => row.team1+" vs "+row.team2, width:"350px",wrap:true},
    { name: "Fancy Name", selector: (row) => row.runnerName,width:"400px",wrap:true},
    // { name: "Lay",selector:(row)=>row.layPrice},
    // { name: "Bsack",selector:(row)=>row.backPrice},
    {
      name: "Declare Runs",
      cell: (row) => (
        <div>
          <input type="number" placeholder='Enter Run' onChange={(e)=>{setRuns(e.target.value)}} />
          <button style={{color:'white',border:'none',backgroundColor:"green" ,margin:"5px",padding:"5px 10px"}} onClick={() => handleDeclareClick(row.selection_id, row.match_id, runs)}>Declare</button>
        </div>
      )
    }
  ];

  const handleDeclareClick = (fancyId, matchId, runs) => {
    confirmAlert({
      title: 'Confirm to declare',
      message: `Are you sure to declare Fancy ${fancyId} for Match ${matchId} of Runs ${runs}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            declareFancy(fancyId, matchId, runs);
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  return (
    <div>
      <div style={{ margin: "10px 30px" }}>
        <h1 style={{ textAlign: 'center', color: 'white' }}>Declare Fancy</h1>
        <Table data={fancies} columns={columns} />
      </div>
    </div>
  );
}
