import React, { useState, useEffect } from "react";
import Table from "../../Components/Table/Table";
import axios from "../../authAxios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Swal from "sweetalert2";


export default function MatchUndeclaration() {
  const [matches, setMatches] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  const fetchData = async () => {
    try {
      const result = await axios.get("/declare/getdeclareMatches");
      console.log(result);
      setMatches(result.data.dataobj);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();

    // Polling every 30 seconds
    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  const undeclareMatch = async (match_id) => {
    try {
      const res = await axios.post("/declare/undeclareMatch", {
        match_id,
      });
      fetchData();
    } catch (error) {
      handlErrorPop(error);
    }
  };

  const handlErrorPop = (errMsg) => {
    confirmAlert({
      title: "Reminder",
      message: errMsg,
      buttons: [
        {
          label: "Ok",
          onClick: () => {},
        },
      ],
    });
  };
  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const columns = [
    { name: "matchId", selector: (row) => row.match_id },
    {
      name: "Start Date/Time",
      selector: (row) =>
        new Date(row.start_time).toLocaleString("en-US", {
          timeZone: "Asia/Kolkata",
        }),
    },
    { name: "Team 1", selector: (row) => row.team1 },
    { name: "Team 2", selector: (row) => row.team2 },
    {
      name: "Declare",
      cell: (row) => (
        <button
          style={{
            color: "white",
            border: "none",
            backgroundColor: "green",
            margin: "5px",
            padding: "5px 10px",
          }}
          onClick={() =>
            handleWinningTeamClick(row.match_id, row.team1, row.team2)
          }
        >
          UnDeclare
        </button>
      ),
    },
  ];

  const handleWinningTeamClick = async (match_id, team1, team2) => {
    // confirmAlert({
    //   title: "Result:" ,
    //   message: "Are you sure you want to do this?",
    //   childrenElement:<div>
    //       <div>
    //       <select style={{width:"200px"}} onChange={handleSelectChange}>
    //       <option key="" value="">
    //           select
    //         </option>
    //         <option key={team1} value={team1}>
    //           {team1}
    //         </option>
    //         <option key={team2} value={team2}>
    //           {team2}
    //         </option>
    //         <option key="Abundant" value="Abundant">
    //           Abundant
    //         </option>
    //         <option key="Tied" value="Tied">
    //           Tied
    //         </option>
    //       </select>

    //     </div>
    //   </div>,
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {
    //         undeclareMatch(match_id, selectedValue, team1, team2);
    //       },
    //     },
    //     {
    //       label: "No",
    //       onClick: () => {},
    //     },
    //   ],
    // });

    Swal.fire({
      title: `Undeclare match: ${team1} vs  ${team2}`,
      showCancelButton: true,
      confirmButtonText: "Yes, Confirm it!",
    }).then(() => {
      undeclareMatch(match_id);
    });
  };

  return (
    <div>
      <div style={{ margin: "10px 30px" }}>
        <h1 style={{ textAlign: "center", color: "white" }}>Undeclare Matches</h1>
        <Table data={matches} columns={columns} />
      </div>
    </div>
  );
}
