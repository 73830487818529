import React, { useState, useEffect } from 'react';
import Table from '../../Components/Table/Table';
import axios from "../../authAxios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function TossDeclaration() {
  const [matches, setMatches] = useState([]);

  const fetchData = async () => {
    try {
      const result = await axios.get("/declare/getUndeclareTossMatch");
      console.log(result);
      setMatches(result.data.dataobj);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();

    // Polling every 30 seconds
    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  const declareMatch = async (match_id,winner,loser) => {
    try {
      const res = await axios.post("/declare/toss-match", { match_id,winner,loser });
      console.log(res);
      fetchData();
    } catch (error) {
      handlErrorPop(error)
    }
  };

  const handlErrorPop = (errMsg)=>{
    confirmAlert({
      title: "Reminder",
      message: 'Please declare fancy of this match',
      buttons: [
        {
          label: 'Ok',
          onClick: () => { }
        }
      ]
    });
  }
  const columns = [
    { name: "matchId", selector: (row) => row.match_id },
    { name: "Team 1", selector: (row) => row.team1 },
    { name: "Team 2", selector: (row) => row.team2 },
    {
      name: "Declare",
      cell: (row) => (
        <div>
          <button onClick={() => handleWinningTeamClick(row.match_id, row.team1,row.team2)}>Team 1</button>
          <button onClick={() => handleWinningTeamClick(row.match_id, row.team2,row.team1)}>Team 2</button>
        </div>
      )
    }
  ];

  const handleWinningTeamClick = (match_id, winTeam,loseTeam) => {
    confirmAlert({
      title: 'Winner:' + winTeam,
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            declareMatch(match_id,winTeam,loseTeam);
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  };

  return (
    <div>
      <div style={{ margin: "10px 30px" }}>
        <h1 style={{ textAlign: 'center', color: 'white' }}>Declare Toss</h1>
        <Table data={matches} columns={columns} />
      </div>
    </div>
  );
}
