import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./Pages/Login/Login";
import MatchDeclaration from "./Pages/MatchDeclaration/MatchDeclaration";
import Dashoboard from "./Pages/Dashboard/Dashoboard";
import FancyDeclaration from "./Pages/FancyDeclaration/FancyDeclaration";
import Cookies from "js-cookie";
import NotFound from "./Pages/NotFound/NotFound";
import TossDeclaration from "./Pages/TossDeclaration/TossDeclaration";
import MatchUndeclaration from "./Pages/MatchUndeclaration/MatchUndeclaration";

function AppRoutes() {
  return (
    <div>
      <PrivateRoutes />
    </div>
  );
}

export default AppRoutes;
const PrivateRoutes = () => {
  const navigate = useNavigate();
  if (Cookies.get("jwtToken") && localStorage.getItem("isUserLoggedIn")) {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashoboard />} />
        <Route path="/match-declaration" element={<MatchDeclaration />} />
        <Route path="/fancy-declaration" element={<FancyDeclaration />} />
        <Route path="/toss-declaration" element={<TossDeclaration />} />
        <Route path="/match-undeclaration" element={<MatchUndeclaration />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="*" element={<Login />} />;
      </Routes>
    );
  }
};
