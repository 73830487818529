import React from "react";
import { MdOutlineRefresh, MdSportsCricket } from "react-icons/md";
import { FaFantasyFlightGames } from "react-icons/fa";
import { GiCrownCoin } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { MdRefresh } from "react-icons/md";
import axios from "../../authAxios";
import { MdRemoveDone } from "react-icons/md";

export default function Dashoboard() {
  const navigate = useNavigate();

  const onLogoutPress = ()=>{
      localStorage.clear();
      navigate("/");
  }
 
  return (
  <div style={{height:"100vh"}}>
    <div style={{display:'flex',justifyContent:'right' ,padding:"10px 20px"}}>
      <button onClick={onLogoutPress} style={{color:'white',backgroundColor:"#735cff",border:"none",padding:'8px 16px' ,borderRadius:"8px"}}>Logout</button>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
       <Tile title={"Match Declartion"} path={"/match-declaration"} Icon={MdSportsCricket}/>
       <Tile title={"Fancy Declartion"} path={"/fancy-declaration"} Icon={FaFantasyFlightGames}/>
       <Tile title={"Toss Declartion"} path={"/toss-declaration"} Icon={GiCrownCoin}/>
       <Tile title={"Match Undeclartion"} path={"/match-undeclaration"} Icon={MdRemoveDone}/>
       <RefreshBtn  title={"Refresh Match"} Icon={MdRefresh}/>
       {/* <button onClick={()=>refreshMatches()}>Refresh Matches {MdRefresh}</button> */}
      </div>

    </div>
    </div>
  );
}

const Tile = ({title,path,Icon}) => {
    const navigate = useNavigate();
    const handleDeclarationClick = (path) => {
       navigate(path)
    };
  return (
    <div
      onClick={() => {
        handleDeclarationClick(path);
      }}
      style={{
        width: "170px",
        height: "170px",
        textAlign: "center",
        background: "#735cff",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        position: "relative",
        boxShadow: "1px 1px 10px white",
        margin:"10px"
      }}
    >
      <div></div>
      <Icon size={50} />
      <h3 style={{ color: "white", position: "absolute", bottom: 0 }}>
        {title}
      </h3>
    </div>
  );
};

const RefreshBtn = ({title,Icon}) => {
  const navigate = useNavigate();
  const refreshMatches = async ()=>{
    try {
      var res = await axios.get("/t-p/cron");
      if(res.data.status){
        alert("Refresh Successfully")
      }else{
        alert("Error")
      }
    } catch (error) {
      console.log(error);
    }
      
}

return (
  <button
    onClick={() => {
      refreshMatches();
    }}
    style={{
      width: "170px",
      // height: "170px",
      border:"none",
      textAlign: "center",
      background: "#735cff",
      borderRadius: "15px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
      position: "relative",
      boxShadow: "1px 1px 10px white",
      margin:"10px"
    }}
  >
    <div></div>
    <Icon size={50} />
    <h3 style={{ color: "white", position: "absolute", bottom: 0 }}>
      {title}
    </h3>
  </button>
);
};

